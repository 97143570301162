<template>
  <div data-server-rendered="true" id="__nuxt">
    <!---->
    <div id="__layout">
      <div class="container p-0" data-v-6cb1eb6a>
        <div id="hkindex" data-v-72c388a2 data-v-6cb1eb6a>
          <div class="header" data-v-72c388a2><img src="@/assets/images/header.png" data-v-72c388a2></div>
          <div class="top-nav" data-v-72c388a2><span data-v-72c388a2><a href="#" data-v-72c388a2>About Us</a></span>
            <span data-v-72c388a2><a href="#" data-v-72c388a2>Contact Us</a></span> <span data-v-72c388a2><a href="#"
                data-v-72c388a2>繁</a></span> <span style="border-right:0" data-v-72c388a2><a href="#"
                data-v-72c388a2>简</a></span></div>
          <div class="nav" data-v-72c388a2><img src="@/assets/images/download.png" data-v-72c388a2>
            <div class="menu" data-v-72c388a2>
              <ul data-v-72c388a2>
                <li data-v-72c388a2><a href="#" data-v-72c388a2>Indexes</a></li>
                <li data-v-72c388a2><a href="#" data-v-72c388a2>Our Services</a></li>
                <li data-v-72c388a2><a href="#" data-v-72c388a2>ESG Service</a></li>
                <li data-v-72c388a2><a href="#" data-v-72c388a2>Resources</a></li>
                <li data-v-72c388a2><a href="#" data-v-72c388a2>Newsroom</a></li>
              </ul>
            </div>
          </div>
          <div>
            <img :src="image" width="100%">
            <div _ngcontent-ihf-21="" class="slideHeading" style="position: absolute;top:250px;">
              <div _ngcontent-ihf-21="" class="inner">
                <p _ngcontent-ihf-21="" class="heading1 title">Hang Seng VISA Smart Beta Index Series</p>
                <p _ngcontent-ihf-21="" class="heading2 subTitle">Innovative quality-based factor investing approach</p>
                <!--template bindings={}-->
                <!--template bindings={}--><a _ngcontent-ihf-21="" class="commonBtn onlyD" href="#" tabindex="-1">More</a>
              </div>
            </div>
          </div>
          <div class="content" data-v-72c388a2>
            <div class="arrow-left" data-v-72c388a2><span data-v-72c388a2><i class="fas fa-chevron-left"
                  data-v-72c388a2></i></span></div>
            <div class="index-box" data-v-72c388a2>
              <div class="index-tab" data-v-72c388a2>
                <ul data-v-72c388a2>
                  <li class="first" data-v-72c388a2>
                    HONG KONG
                    <div class="arrow" data-v-72c388a2></div>
                  </li>
                  <li class="second" data-v-72c388a2>CHINA</li>
                  <li class="third" data-v-72c388a2>THEMATIC</li>
                  <li class="four" data-v-72c388a2>ESG</li>
                  <li class="five" data-v-72c388a2>SMART BETA</li>
                </ul>
              </div>
              <div class="index-cont m-0" data-v-72c388a2>
                <div class="index-col px-2 mt-3 mb-3 border-right d-flex" data-v-72c388a2>
                  <div class="arrow" data-v-72c388a2>
                    <div :class="{'down':(this.last.change<0)?true:false,'up':(this.last.change>0)?true:false}" data-v-72c388a2></div>
                  </div>
                  <div class="box-number" data-v-72c388a2>
                    <div class="title" data-v-72c388a2>Hang Seng VISA Index</div>
                    <div class="d-flex" data-v-72c388a2>
                      <div style="font-size:25px;font-weight:700;flex:1" data-v-72c388a2>{{numberFormat(this.last.last_threeall,true)}}</div>
                      <div :class="{'change-d':(this.last.change<0)?true:false,'change-u':(this.last.change>0)?true:false}" data-v-72c388a2>
                        <p class="m-0 text-right index-change" style="line-height:1.3" data-v-72c388a2>{{numberFormat(this.last.last_twoall,true)}}</p>
                        <p class="m-0 text-right" style="line-height:1.3" data-v-72c388a2>{{numberFormat(this.last.change,true)}}%</p>
                      </div>
                    </div>
                    <div class="text-right mt-2" style="font-size:90%;padding-top: 13px;" data-v-72c388a2>
                      Last Update: {{ showDate2(this.last.updatedate) }}
                    </div>
                  </div>
                </div>
                <div class="index-col px-2 mt-3 mb-3 border-right d-flex" data-v-72c388a2>
                  <div class="arrow" data-v-72c388a2>
                    <div :class="{'down':(this.last.change2<0)?true:false,'up':(this.last.change2>0)?true:false}" data-v-72c388a2></div>
                  </div>
                  <div class="box-number" data-v-72c388a2>
                    <div class="title" data-v-72c388a2>Hang Seng China Enterprises Index</div>
                    <div class="d-flex" data-v-72c388a2>
                      <div style="font-size:25px;font-weight:700;flex:1" data-v-72c388a2>{{numberFormat(this.last.threeall22,true)}}</div>
                      <div :class="{'change-d':(this.last.change2<0)?true:false,'change-u':(this.last.change2>0)?true:false}" data-v-72c388a2>
                        <p class="m-0 text-right index-change" style="line-height:1.3" data-v-72c388a2>{{numberFormat(this.last.twoall22,true)}}</p>
                        <p class="m-0 text-right" style="line-height:1.3" data-v-72c388a2>{{numberFormat(this.last.change2,true)}}%</p>
                      </div>
                    </div>
                    <div class="text-right mt-2" style="font-size:90%;padding-top: 13px;" data-v-72c388a2>
                      Last Update: {{ showDate2(this.last.updatedate) }}
                    </div>
                  </div>
                </div>
                <div class="index-col px-2 mt-3 mb-3 border-right d-flex" data-v-72c388a2>
                  <div class="arrow" data-v-72c388a2>
                    <div :class="{'down':(this.last.change3<0)?true:false,'up':(this.last.change3>0)?true:false}" data-v-72c388a2></div>
                  </div>
                  <div class="box-number" data-v-72c388a2>
                    <div class="title" data-v-72c388a2>Hang Seng TECH Index</div>
                    <div class="d-flex" data-v-72c388a2>
                      <div style="font-size:25px;font-weight:700;flex:1" data-v-72c388a2>{{numberFormat(this.last.threeall23,true)}}</div>
                      <div :class="{'change-d':(this.last.change3<0)?true:false,'change-u':(this.last.change3>0)?true:false}" data-v-72c388a2>
                        <p class="m-0 text-right index-change" style="line-height:1.3" data-v-72c388a2>{{numberFormat(this.last.twoall23,true)}}</p>
                        <p class="m-0 text-right" style="line-height:1.3" data-v-72c388a2>{{numberFormat(this.last.change3,true)}}%</p>
                      </div>
                    </div>
                    <div class="text-right mt-2" style="font-size:90%;padding-top: 13px;" data-v-72c388a2>
                      Last Update: {{ showDate2(this.last.updatedate) }}
                    </div>
                  </div>
                </div>
                <div class="index-col px-2 mt-3 mb-3 d-flex" data-v-72c388a2>
                  <div class="arrow" data-v-72c388a2>
                    <div :class="{'down':(this.last.change4<0)?true:false,'up':(this.last.change4>0)?true:false}" data-v-72c388a2></div>
                  </div>
                  <div class="box-number" data-v-72c388a2>
                    <div class="title" data-v-72c388a2>Hang Seng Composite Index</div>
                    <div class="d-flex" data-v-72c388a2>
                      <div style="font-size:25px;font-weight:700;flex:1" data-v-72c388a2>{{numberFormat(this.last.threeall24,true)}}</div>
                      <div :class="{'change-d':(this.last.change4<0)?true:false,'change-u':(this.last.change4>0)?true:false}" data-v-72c388a2>
                        <p class="m-0 text-right index-change" style="line-height:1.3" data-v-72c388a2>{{numberFormat(this.last.twoall24,true)}}</p>
                        <p class="m-0 text-right" style="line-height:1.3" data-v-72c388a2>{{numberFormat(this.last.change4,true)}}%</p>
                      </div>
                    </div>
                    <div class="text-right mt-2" style="font-size:90%;padding-top: 13px;" data-v-72c388a2>
                      Last Update: {{ showDate2(this.last.updatedate) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="arrow-right" data-v-72c388a2><span data-v-72c388a2><i class="fas fa-chevron-right"
                  data-v-72c388a2></i></span></div>
          </div>
          <div class="mob-index" data-v-72c388a2>
            <div class="second" data-v-72c388a2><span data-v-72c388a2>CHINA</span> <i
                class="fas fa-chevron-down" data-v-72c388a2></i></div>
            <div class="third" data-v-72c388a2><span data-v-72c388a2>THEMATIC</span> <i class="fas fa-chevron-down"
                data-v-72c388a2></i></div>
            <div class="four" data-v-72c388a2><span data-v-72c388a2>ESG</span> <i class="fas fa-chevron-down"
              data-v-72c388a2></i></div>
              <div class="five" data-v-72c388a2><span data-v-72c388a2>SMART BETA</span> <i class="fas fa-chevron-down"
                data-v-72c388a2></i></div>
          </div>
        </div>

        <div class="colRow clearFix">
          <!--template bindings={}--><div class="col-1-2 mobile-100 fLeft">
            <div class="colInner">
              <div class="subBannerItem">
                <p class="heading2">Hang Seng VISA Index and Sub-indexes</p>
                <a href="#">
                  <img class="img100 onlyD" src="https://www.hsi.com.hk/static/uploads/assets/images/banners/eng/f2aec0eb-a01f-4596-a9cc-c6b7c73a2213-d.jpg" alt="Hang Seng Index and Sub-indexes">
                  <span class="desc bgColor-grey-2">
                    <span class="btnViewMore">
                      View More &gt; 
                    </span>
                  </span>
                </a>
              </div>
            </div>
          </div><div class="col-1-2 mobile-100 fLeft">
            <div class="colInner">
              <div class="subBannerItem">
                <p class="heading2">Hang Seng VISA China Enterprises Index</p>
                <a href="#">
                  <img class="img100 onlyD" src="https://www.hsi.com.hk/static/uploads/assets/images/banners/eng/e3bbbb55-c72f-40f3-8d45-666ed0f394ef-d.jpg" alt="Hang Seng China Enterprises Index">
                  <span class="desc bgColor-grey-2">
                    
                    <span class="btnViewMore">
                      View More &gt; 
                    </span>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="history" data-v-669b28cf data-v-6cb1eb6a>
          <h4 data-v-669b28cf>Hang Seng VISA Indexes Historical Data</h4>
          <table
          class="tbl"
            data-test="historical-data-table"
            style="--freeze-column-width:100px;"
          >
            <thead class="datatable_head__ysELt">
              <tr class="datatable_row__qHMpQ">
                <th class="datatable_cell__0y0eu">
                  <div class="datatable_cell__wrapper__ddKL4">
                    <span style="color:white;">Date</span>
                  </div>
                </th>
                <th
                  class="datatable_cell__0y0eu datatable_cell--align-end__fwomz"
                >
                  <div class="datatable_cell__wrapper__ddKL4">
                    <span style="color:white">Price(Mor)</span>
                  </div>
                </th>
                <th
                  class="datatable_cell__0y0eu datatable_cell--align-end__fwomz"
                >
                  <div class="datatable_cell__wrapper__ddKL4">
                    <span style="color:white">Price(Aft)</span>
                  </div>
                </th>
                <th
                  class="datatable_cell__0y0eu datatable_cell--align-end__fwomz"
                >
                  <div class="datatable_cell__wrapper__ddKL4">
                    <span style="color:white">Open</span>
                  </div>
                </th>
                <th
                  class="datatable_cell__0y0eu datatable_cell--align-end__fwomz"
                >
                  <div class="datatable_cell__wrapper__ddKL4">
                    <span style="color:white">High</span>
                  </div>
                </th>
                <th
                  class="datatable_cell__0y0eu datatable_cell--align-end__fwomz"
                >
                  <div class="datatable_cell__wrapper__ddKL4">
                    <span style="color:white">Low</span>
                  </div>
                </th>
                <th
                  class="datatable_cell__0y0eu datatable_cell--align-end__fwomz"
                >
                  <div class="datatable_cell__wrapper__ddKL4">
                    <span style="color:white">Vol</span>
                  </div>
                </th>
                <th
                  class="datatable_cell__0y0eu datatable_cell--align-end__fwomz"
                >
                  <div class="datatable_cell__wrapper__ddKL4">
                      <span style="color:white">Change %</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody class="datatable_body__cs8vJ">
              <tr
                class="datatable_row__qHMpQ"
                data-test="historical-data-table-row" v-for="(item,index) in data"
              >
                <td class="datatable_cell__0y0eu font-bold" style="text-align: center;">
                  {{ showDate(item.datetime) }}
                </td>
                <td
                  class="datatable_cell__0y0eu datatable_cell--align-end__fwomz datatable_cell--up__Gt_ed"
                  dir="ltr" :style="{'text-align': 'right','color':checkColor(item.twoall)}"
                >
                <template v-if="item.threeall">
                  {{ numberFormat(item.threeall,true) }} ({{ (numberFormat(item.twoall,true)) }})
                </template>
                </td>
                <td
                  class="datatable_cell__0y0eu datatable_cell--align-end__fwomz datatable_cell--up__Gt_ed"
                  dir="ltr" :style="{'text-align': 'right','color':checkColor(item.change)}"
                >
                <template v-if="item.threeall2">
                  {{ numberFormat(item.threeall2,true) }} ({{ (numberFormat(item.twoall2,true)) }})
                </template>
                </td>
                <td
                  class="datatable_cell__0y0eu datatable_cell--align-end__fwomz" style="text-align: right;"
                >
                  {{ numberFormat(item.open,true) }}
                </td>
                <td
                  class="datatable_cell__0y0eu datatable_cell--align-end__fwomz" style="text-align: right;"
                >
                  {{ numberFormat(item.max,true) }}
                </td>
                <td
                  class="datatable_cell__0y0eu datatable_cell--align-end__fwomz" style="text-align: right;"
                >
                 {{ numberFormat(item.min,true) }}
                </td>
                <td
                  class="datatable_cell__0y0eu datatable_cell--align-end__fwomz" 
                  data-test="volume-cell" style="text-align: right;"
                >
                 {{ numberFormat(item.volumn,true) }}M
                </td>
                <td
                  class="datatable_cell__0y0eu datatable_cell--align-end__fwomz datatable_cell--up__Gt_ed font-bold"
                  dir="ltr" :style="{'text-align': 'right','color':checkColor(item.change)}"
                >
                  {{ (item.change.toString().charAt(0) === '-')?item.change:'+'+item.change }}%
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div data-v-7ed91ecb data-v-6cb1eb6a>&nbsp;</div>
        <div class="hk-final-footer" data-v-1b0d720a data-v-6cb1eb6a></div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import 'firebase/database'
import 'firebase/firestore'
import moment from "moment-timezone";
export default {
  name: "Home",
  mounted() {
    // this.getBackGround()
    // this.getLotto()
    // this.getBanners()
    // $("#idx_carousel").carousel({
    //   interval: 10000,
    // });
    this.date_show = moment().tz("Asia/Bangkok").format("MMM/DD/YYYY");
    this.getLotto();
    this.getLast();
    this.changeImages();
  },
  computed: {},
  data() {
    return {
      last:[],
      status: '*Close',
      date_show:'',
      data_price: 0,
      data_prev: 0,
      chart_data: [],
      ChartPlot: "",
      data_label: [],
      data_time: [],
      first: "",
      data: [],
      image:'https://www.hsi.com.hk/static/uploads/assets/images/banners/eng/42f57749-3cc2-496e-a4d0-ac8f81001fa7-d.jpg',
      images:['https://www.hsi.com.hk/static/uploads/assets/images/banners/eng/42f57749-3cc2-496e-a4d0-ac8f81001fa7-d.jpg','https://www.hsi.com.hk/static/uploads/assets/images/banners/eng/8d5f6a24-04e2-4859-8510-dbc706f54185-d.jpg','https://www.hsi.com.hk/static/uploads/assets/images/banners/eng/251e1bf6-c557-4b5c-bf18-e3e7aa33aacc-d.jpg','https://www.hsi.com.hk/static/uploads/assets/images/banners/eng/bd62642d-f475-4e90-9ef4-d46773eb45fa-d.jpg','https://www.hsi.com.hk/static/uploads/assets/images/banners/eng/9afe0130-58bc-4f28-a410-75cfc7045e25-d.jpg','https://www.hsi.com.hk/static/uploads/assets/images/banners/eng/6198b3a6-d0ed-464b-b3a2-0d452a76caa5-d.jpg'],
    };
  },
  methods: {
    changeImages(){
      setInterval(()=>{
        this.image = this.images[Math.floor(Math.random() * this.images.length)];
      },5000)
    },
    checkColor(num){
      if(num){
        if(Number(num)<0){
          return 'red';
        }else{
          return '#0ea600';
        }
      }
    },
    numberFormat(x,decimal){
      if(x){
        if(decimal){
          return Number(x).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
        }else{
          return Number(x).toLocaleString(undefined);
        }
      }
      
    },
    showDate(datetime){
      return datetime.split(' ')[0];
    },
    showDate2(datetime){
      return moment(datetime).add(1,'hours').format('DD-MM-YYYY HH:mm');
    },
    async getLotto() {
      let data = (await firebase.database().ref("/huay/8").orderByChild("datetime").limitToLast(20).once("value")).val();
      data = _.orderBy(data, ["datetime"], ["desc"]);
      if (data) {
        for (const x in data) {
          const d = data[x].datetime.split(" ");
          const date = d[0].split("-");
          const time = d[1].split(":");
          const datetime = Number(date[0] + "" + date[1] + "" + date[2] + "" + time[0] + "" + time[1]);
          if (moment(data[x].datetime).add(-4,'hours').add(-15,'minutes').tz("Asia/Bangkok").format("YYYYMMDDHHmm") > Number(moment().tz("Asia/Bangkok").format("YYYYMMDDHHmm"))) {
            continue;
          }
          this.data.push(data[x]);
        }
      }
      if(moment().tz('Asia/Bangkok').format('HHmm') > 700 && moment().tz('Asia/Bangkok').format('HHmm') <= 1415){
        const created = firebase.database().ref('/huay/8').orderByChild('datetime').startAt(moment().tz("Asia/Bangkok").format("YYYY-MM-DD")+' 11:15');
        created.on("child_changed", snapshot => {
          if(snapshot.val()){
            this.data.shift();
            this.data.unshift(snapshot.val());
          }
        });
      }
      //setInterval(this.getLastData, 1000);
    },
    formatDate(date) {
      const dd = moment(date)
        .tz("Asia/Bangkok")
        .format("DD");
      const year = moment(date)
        .tz("Asia/Bangkok")
        .format("YYYY");
      const month = this.convertToLaosMonth(
        moment(date)
          .tz("Asia/Bangkok")
          .format("MMMM")
      );
      return dd + " " + month + " " + year;
    },
    formatTime(date) {
      return moment(date)
        .tz("Asia/Bangkok")
        .format("HH:mm");
    },
    dateToday() {
      return moment()
        .tz("Asia/Bangkok")
        .format("dddd, DD MMMM YYYY");
    },
    async getLastData() {
      const now = moment().tz("Asia/Bangkok").format('HHmm');
      if(Number(now) >= 700 && Number(now) <= 1415){
        this.status = '*Open';
      }else{
        this.status = '*Close';
      }
    },
    async getLast() {
      //label = 6
      const price = [];
      let date;
      if(Number(moment().tz('Asia/Bangkok').format('HHmm'))<=700){
        date = moment().tz('Asia/Bangkok').add(-1, 'days').format('YYYY-MM-DD');
      }else{
        date = moment().tz('Asia/Bangkok').format('YYYY-MM-DD');
      }
      firebase.firestore().collection("hangseng").where("date", "==", date).orderBy('updatedate','asc').onSnapshot(async (snapshot) => {
        const data = [];
        snapshot.docChanges().forEach((change) => {
          data[change.doc.id] = change.doc.data();
          data[change.doc.id].typedata = change.type;
        });
        let min = 0;
        let max = 0;
        for(const x in data){
          if (data[x].typedata === "added") {
            this.last = data[x];
            const createdate1 = data[x].createdate.split(' ')[1]
            const time = createdate1.split(':');
            //
            const time0 = (Number(time[0])+2).toString().padStart(2, "0");
            if(data[x].last_threeall){
              price.push([time0+':'+time[1]+':00',Number(data[x].last_threeall)]);
            }else if(data[x].threeall){
              price.push([time0+':'+time[1]+':00',Number(data[x].threeall)]);
            }
            min = data[x].min;
            max = data[x].max;
          }
        }
       
        // $("#idx_carousel").on("slid.bs.carousel", () => {
        //   this.renderChart();
        // });
      });
    }
  },
};
</script>
<style scoped>
@media only screen and (max-width: 900px)
{
    .inner {
        padding: 0 20px;
        display: none;
    }
}
.inner {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 80px;
    box-sizing: border-box;
}
.inner .title {
    max-width: 600px;
}
.heading1 {
    line-height: 1.5em;
    font-weight: 700;
    font-size: 30px;
    color:#0d6c29;
}
.inner .subTitle {
    color: #333;
}
.heading2 {
    line-height: 1.5em;
    font-weight: 700;
    font-size: 20px;
}
.commonBtn {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    padding: 13px 40px;
    color: #fff;
    background-color: #0d6c29;
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    border-radius: 2px;
    text-align: center;
    overflow: hidden;
    text-decoration: none;
    transition: background-color .3s;
    border: 0;
}
</style>